const MILLISECONDS_IN_SECOND = 1000
const MILLISECONDS_IN_MINUTE = 60 * MILLISECONDS_IN_SECOND
const MILLISECONDS_IN_HOUR = 60 * MILLISECONDS_IN_MINUTE
const MILLISECONDS_IN_DAY = 24 * MILLISECONDS_IN_HOUR

const DRF_DURATION_FORMAT_REGEX = /(?:(\d+)d)?\s?(?:(\d+):)?(\d+):(\d+)(?:\.(\d+))?/ // '[DD] HH:MM:ss[.uuuuuu]'

export class UpToSecondsHumanizer {
  // DD days HH hours MM minutes SS seconds
  constructor(duration) {
    this.duration = duration
    return this
  }

  format() {
    const days = Math.floor(this.duration / MILLISECONDS_IN_DAY)
    const hours = Math.floor((this.duration % MILLISECONDS_IN_DAY) / MILLISECONDS_IN_HOUR)
    const minutes = Math.floor((this.duration % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE)
    const seconds = Math.floor((this.duration % MILLISECONDS_IN_MINUTE) / MILLISECONDS_IN_SECOND)

    return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds}s`
  }
}

export class Duration {
  constructor(rawDuration) {
    this.miliseconds = this.toMilliseconds(rawDuration)
    return this
  }

  valueOf() {
    return this.miliseconds
  }

  add(duration) {
    return new Duration(this + duration)
  }

  deduct(duration) {
    return new Duration(this - duration)
  }

  humanize(formatter = UpToSecondsHumanizer) {
    return new formatter(this).format()
  }

  toMilliseconds(rawDuration) {
    if (this.isDRFDuration(rawDuration)) {
      return this.DRFDurationToMilliseconds(rawDuration)
    }
    if (typeof rawDuration === 'number') {
      return rawDuration
    }
    if (rawDuration instanceof Duration) {
      return rawDuration.miliseconds
    }
    return 0
  }

  isDRFDuration(rawDuration) {
    return DRF_DURATION_FORMAT_REGEX.test(rawDuration)
  }

  DRFDurationToMilliseconds(rawDuration) {
    const parts = DRF_DURATION_FORMAT_REGEX.exec(rawDuration)
    if (!parts) {
      return 0
    }
    const days = parseInt(parts[1], 10) || 0
    const hours = parseInt(parts[2], 10) || 0
    const minutes = parseInt(parts[3], 10) || 0
    const seconds = parseInt(parts[4], 10) || 0
    const milliseconds = (parseInt(parts[5], 10) || 0) / 1000

    return (
      days * MILLISECONDS_IN_DAY +
      hours * MILLISECONDS_IN_HOUR +
      minutes * MILLISECONDS_IN_MINUTE +
      seconds * MILLISECONDS_IN_SECOND +
      milliseconds
    )
  }
}

export function serverDurationToMinutes(duration) {
  // Duration to be used instead
  const parts = duration.split(':')
  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseFloat(parts[2])

  return hours * 60 + minutes + seconds / 60
}

import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { RefreshScheme } from '~auth/runtime'
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/account",
    "logout": "/account",
    "home": "/",
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new RefreshScheme($auth, {
  "token": {
    "property": "access",
    "type": "Bearer",
    "maxAge": 3600
  },
  "refreshToken": {
    "property": "refresh",
    "data": "refresh",
    "maxAge": 604800
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "endpoints": {
    "login": {
      "url": "api/v1/auth/jwt/create/",
      "method": "post",
      "propertyName": false
    },
    "refresh": {
      "url": "api/v1/auth/jwt/refresh/",
      "method": "post",
      "propertyName": false
    },
    "user": {
      "url": "api/v1/auth/users/me/",
      "method": "get"
    },
    "logout": false
  },
  "name": "local"
}))

  // google
  $auth.registerStrategy('google', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://accounts.google.com/o/oauth2/auth",
    "userInfo": "api/v1/auth/users/me/",
    "token": "api/v1/auth/social/google/"
  },
  "responseType": "code",
  "access_type": "offline",
  "redirectUri": "https://rythmex.com/account",
  "clientId": "197314791123-4r79vjaadtkli25f9m83ep7h4rt6iep2.apps.googleusercontent.com",
  "scope": [
    "openid",
    "profile",
    "email",
    "openid",
    "profile",
    "email"
  ],
  "codeChallengeMethod": "",
  "token": {
    "property": "access",
    "type": "Bearer",
    "maxAge": 3600
  },
  "refreshToken": {
    "property": "refresh",
    "data": "refresh",
    "maxAge": 604800
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "logout": false,
  "name": "google"
}))

  // facebook
  $auth.registerStrategy('facebook', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://facebook.com/v2.12/dialog/oauth",
    "userInfo": "api/v1/auth/users/me/",
    "token": "api/v1/auth/social/facebook/"
  },
  "responseType": "code",
  "redirectUri": "https://rythmex.com/account",
  "clientId": "584247656022842",
  "scope": [
    "public_profile",
    "email",
    "public_profile",
    "email"
  ],
  "token": {
    "property": "access",
    "type": "Bearer",
    "maxAge": 3600
  },
  "refreshToken": {
    "property": "refresh",
    "data": "refresh",
    "maxAge": 604800
  },
  "user": {
    "property": false,
    "autoFetch": true
  },
  "logout": false,
  "name": "facebook"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
